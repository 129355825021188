body{
    margin: 0;
    padding: 0;
    background-image: url('https://api.regnodeicieli.cc/images/steinwand_klein.jpg'); /* URL of your chosen background image */
    background-size: cover; /* Cover the entire area of the element */
    background-position: center; /* Center the background image */
    background-attachment: fixed; /* Keep the background fixed during scrolling */
    min-height: 100vh; /* Minimum height of the header */
    width: 100%; /* Full width */
    overflow: -ms-autohiding-scrollbar;
    width: 100vW;
    height: 100vH;
}

body::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, newer Edge */
  }